import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeModule } from '@angular/material/tree';
import { AccessLevel, DTO } from 'src/dto/dto';

import { UserService } from 'src/app/services/user.service';
import { EDTCodePipe } from 'src/app/utils/pipes/edtcode.pipe';

/** Flat node with expandable and level information */
export interface TreeNode extends DTO {
    _level: number;
    _parent?: TreeNode;
    _expandable: boolean;
    _selected: boolean;
    _children?: TreeNode[];
}

@Component({
    selector: 'app-tree',
    templateUrl: './tree.component.html',
    styleUrls: ['./tree.component.scss'],
    imports: [
        MatTreeModule,
        MatButtonModule,
        MatIconModule,
        EDTCodePipe
    ],
    standalone: true
})
export class TreeComponent {
    private readonly _transformer = (node: TreeNode, level: number) => {
        node._level = level;
        return node;
    };

    public readonly treeControl = new FlatTreeControl<TreeNode>(
        node => node._level,
        node => node._expandable,
    );

    private readonly treeFlattener = new MatTreeFlattener(
        this._transformer,
        node => node._level,
        node => node._expandable,
        node => node._children,
    );

    public dataSourceData = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    @Input() menuMode: boolean = false;
    @Input('cdkTreeNodePadding') treeNodePadding: number = 20;

    private _allowedParentTypes: string[] = null;
    @Input()
    set allowedParentTypes(value) {
        this._allowedParentTypes = value;
    }
    get allowedParentTypes() {
        return this._allowedParentTypes?.length > 0 ? this._allowedParentTypes : null;
    }

    private _asset: DTO = null;
    @Input()
    set asset(asset: DTO) {
        this._asset = asset;
    }
    get asset() {
        return this._asset;
    }

    private _roots: DTO[] = [];
    @Input()
    set roots(roots: DTO[]) {
        this._roots = roots;
        this.dataSourceData.data = this.bindTree(this._roots, 0);
    }
    get roots() {
        return this._roots;
    }

    private _trail: DTO[] = [];
    @Input()
    set trail(trail: DTO[]) {
        this._trail = trail;
        this.followTrail(trail || []); // Select some root -> expand some child -> select some subchild.
    }
    get trail() {
        return this._trail;
    }

    @Input() favValue: string;
    @Input() isMove: boolean = false;


    private _nodeId = 0;
    @Input()
    set nodeId(value: number) {
        this._nodeId = value;
        if (this.selectedNode)
            this.selectedNode._selected = false;
    }
    get nodeId() {
        return this._nodeId;
    }

    selectedNode: TreeNode;

    // TODO: Tell the user about our progress 'life'
    async followTrail(trail: DTO[]) {
        this.dataSourceData.data = [];

        // For each item in the trail, find it and expand it.
        let node = null;
        for (let i=0; i < trail.length; i++) {
            const crumb = trail[i];

            if (i == 0) { // Root level
                await this.retrieveRoots(crumb, crumb.parentId);
                node = this.dataSourceData.data.find(item => item.id == crumb.id);
            }
            else {
                const childNode = node._children.find(item => item.id == crumb.id);
                this.moveToTop(node._children, childNode);

                node = childNode;
            }

            if (!node) break;

            // For Move functionality, if isMove is TRUE, the node will not expand.
            // By default isMove = FALSE, we are making it TRUE only when we are opening
            // tree component from Confirmation Dialog.
            await this.expandAsset(node, true);

            // This added children to the node, for the next iteration.
        }

        // Select the last node we have in the trail.
        if (node) {
            this.selectedNode = node;
            node._selected = true;

            const assetNode = node._children.find(item => item.id == this.asset?.id);
            this.moveToTop(node._children, this.asset);
            if (assetNode) assetNode._disabled = true;
        }
    }

    @Output() selectChange = new EventEmitter<TreeNode[]>();

    async selectAsset(node: TreeNode) {
        if (this.selectedNode) {
            this.selectedNode._selected = false;
        }
        node._selected = true;
        this.nodeId = node.id;
        this.selectedNode = node;
        const trail = [node];
        while (node?._parent) {
            trail.unshift(node._parent);
            node = node._parent;
        }
        this.selectChange.next(trail);
    }

    constructor(public userService: UserService) { }

    moveToTop(list: DTO[], node) {
        if (!node || !list) return;
        const index = list.findIndex(item => item.id == node.id);
        if (index == -1) return;
        list.splice(index, 1);
        list.unshift(node);
    }

    bindTree(nodes: DTO[], level, topNode = null) {
        this.moveToTop(nodes, topNode);

        return nodes?.map(node => {
            const nodeMeta = DTO.getMetaData(node.dto);
            const children = this.bindTree(node[nodeMeta._childrenAt] || [], level + 1);
            // Now every child knows its parent.
            children.forEach(child => child._parent = node);

            const icon = node.icon || nodeMeta._icon;
            const isDisabled = node.access < AccessLevel.READWRITE
                || !this.allowedParentTypes?.includes(node.dto);

            return { ...node,
                _expandable: !!nodeMeta._childrenAt,
                _disabled:   isDisabled,
                _level:      level,
                _children:   children,
                _icon:       icon
            };
        }) || [];
    }

    async expandAsset(node: TreeNode, forceExpand = false) {
        if (!node) return;

        if (forceExpand && node._expandable) {
            this.treeControl.expand(node);
        }

        // If user is expanding expanded a node that already has children, no need to retrieve again.
        if (node._children?.length > 0) return;

        // If the node says it is not expandable, then no need to do anything.
        if (!node._expandable) return;

        // User is expanding but we don't have children.
        // Retrieve the children for this node, and then let the tree control do its thing.
        const nodeMeta = DTO.getMetaData(node.dto);

        try {
            // TODO: Do this via the assetService, and make sure we update THOSE objects.
            const dataNode = await DTO.getEndpointAPI(node.dto).get(node.id);

            const children = dataNode[nodeMeta._childrenAt] || [];
            // TODO: Filter out children of unexpected types.

            node._children = this.bindTree(children, node._level + 1) || [];
            node._children.forEach(child => child._parent = node);
            node._expandable = node._children.length > 0;

            // Trigger tree refresh.
            this.dataSourceData.data = [...this.dataSourceData.data];
        }
        catch (ex) {
            console.error(ex.statusText + " while retrieving " + node.dto + "s: " + (ex.error?.error || ex.error || ex.message));
        }
    }

    public selectTreeNode(node: DTO) {

        if (this.selectedNode) {
            this.selectedNode._selected = false;
        }

        const matchedTreeNode = this.dataSourceData.data.find(x => x.id == node.id);
        if (matchedTreeNode) {
            this.selectedNode = matchedTreeNode;
            this.selectedNode._selected = true;
        }
    }

    private getFilters(asset: DTO, useFavorites: boolean, parentId = null): string | undefined {
        const expressions = [];

        if (useFavorites) {
            const refType = DTO.getReferenceType(asset.dto)
            const favs = this.userService
                .favorites?.filter(f => f.targetType == refType)
                .map(f => f.target);

            if (asset && favs.length > 0 && !favs.includes(asset.id)) {
                favs.unshift(asset.id); // Add the one we are asked to select.
            }
            if (favs.length > 0) {
                expressions.push("(id in (" + favs.join(",") + "))");
            }
        }

        if (parentId) {
            expressions.push("(parentId eq " + parentId + ")");
        }

        // FUTURE: If we want to follow parentage upwards even when not in the trail:
        // return expressions.filter(f => !!f).join(" and ");

        // If we have favorites, use that over the parent ID.
        return expressions[0];
    }

    async retrieveRoots(node: DTO, parentId = null) {
        try {
            // TEMPORARY: Orgs don't have their apps and platforms just yet. So, we should
            // not constrain to a specific parentId (org) in this case. Just show all.
            if (node.dto == "Application_v1" || node.dto == "Platform_v1") parentId = null;

            const filters = this.getFilters(node, this.favValue == "fav", parentId);
            const roots = await DTO.getEndpointAPI(node.dto).get(filters) || [];

            // Initialize tree with these roots.
            this.dataSourceData.data = this.bindTree(roots, 0, node);
            if (this.selectedNode) {
                this.nodeId = this.selectedNode.id;
                this.selectTreeNode(this.selectedNode);
            }
        }
        catch (ex) {
            console.error(ex.statusText + " while retrieving " + node.dto + "s: " + (ex.error?.error || ex.error || ex.message));
        }
    }

    async retrieveChildren(node: TreeNode, childrenDTOName: string) {
        try {
            const data = await DTO.getEndpointAPI(childrenDTOName).get("parentId eq " + node.id) || [];

            // Add the children to this node, and this node to the children.
            node._children = this.bindTree(data, node._level + 1) || [];
            node._children.forEach(child => child._parent = node);
            node._expandable = node._children.length > 0;
        }
        catch (ex) {
            console.error(ex.statusText + " while retrieving " + childrenDTOName + "s: " + (ex.error?.error || ex.error || ex.message));
        }
    }
}
